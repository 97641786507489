export class General{
    GroupId: number;
    Key: string;
    Files:File[];
    images:any;
    MainImage:any;
    GroupDataSource:groupDataSource[];
    macroDeal:macroDeal;
    categories:category[];
    customFileLink:any;
}
export class category{
    id:number;
    name:string
}

export class File{
    categoryId?: number;
    dealId?: number;
    fileType?: string;
    id?: number;
    name?: string;
    uploadDate?: string;
    link:any;
    viewPdf:any
}

export class groupDataSource{
    columns: number;
    fileId: number;
    displayOrder: number;
    formula: string;
    groupId: number;
    id: number;
    intro: string;
    namedRange: string;
    title: string;
    headers:string;
    head:string[]=[];
    Sheet:Sheet;
}

export class Sheet {
    cells:cells[];
    minX:number;
    maxX:number;
    GroupDataSource:groupDataSource;
}
export class cells{
    x:number;
    y:number;
    value:string;
    style : Style;
}

export class Style{
    bold: boolean;
    italic : boolean;
    family : string;
    size : string;
    underlined : boolean;
    background?:BackGround;
    fontColor :FontColor;
    strike : boolean;
    horizontalAlignment : string;
    theme : any;
}

export class BackGround{
    theme: any;
    tint: any;
    rgb : any;
    indexed : any;
    auto : any;
}
export class FontColor{
    theme : any;
    tint : any;
    rgb : any;
    indexed : any;
    auto : any;
}

export class macroDeal{
    address: any;
    city: string;
    description: any;
    macroDealDataSources: any[];
    numberofUnits:number;
    pipedriveId: number;
    pipeline: any;
    squareFootage:string;
    stage: any;
    state: any;
    title: string;
}
export class Quotes {
  title:string;
  id:number;
  ltv:string;
  amount:string;
  fees:string;
  upfrontReserves:string;
  minDSCR:string;
  minDscr:string;
  rescource:string;
  rate:string;
  loanTerm:string;
  armortization:string;
  quoteNotes:string;
}
