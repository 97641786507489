import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/services/client.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  constructor(public activatedRoute: ActivatedRoute,public router: Router ,private ClientService: ClientService 
    , private spinner: NgxSpinnerService,private viewportScroller: ViewportScroller ) { }
    
  ngOnInit(): void {
  }
  
  isShow:boolean=true;
  onSubmit(formValue) {
    console.log(formValue);
    this.ClientService.savemessage(formValue).subscribe(res=>{
      console.log(res); 
    })
  }

  isOverlay = false;
  sendMessage(){    
    this.isOverlay = true;
    setTimeout(() => {
      this.isOverlay = false;
    }, 2000);
  }

  isOverlayEmail = false;
  save(){    
    this.isOverlayEmail = true;
    setTimeout(() => {
      this.isOverlayEmail = false;
    }, 2000);
  }

  emailAddress:string;
  SaveEmail(){
  if (this.emailAddress.length>0) {     
    this.ClientService.saveemail(this.emailAddress).subscribe(res=>{
      console.log(res);
      this.emailAddress='';
      this.save();
    })
  }
  }
}
