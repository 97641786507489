<!-- Start Navbar -->
<nav class="navbar navbar-expand-lg navbar-light bg-light" >
  <div style="display: flex;">
        <img src="../../../assets/images/logo.png" class="snap" alt="Black and White Star in Circle">
        <!-- <a class="logo" >VALOR GROUNDLEASE VENTURES</a> -->
        <!-- <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" style="height: 44px;"
        data-target="#navbarColor03" aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation" (click)="isShow = !isShow">
        <span class="navbar-toggler-icon"></span>
       </button> -->
  </div>
  <div class="collapse navbar-collapse">
        <ul class="navbar-nav mr-auto nav-ul">
            <li class="nav-item active" style="cursor: pointer; font-weight: 500;">
                <input type="email" name="otagsnzuxkpoitncllvcdcrezusenfixvbol" placeholder="Email Address" required="" [(ngModel)]="emailAddress"
                    pattern="^(([^<>()\[\]\\.,;:\s@&quot;]+(\.[^<>()\[\]\\.,;:\s@&quot;]+)*)|(&quot;.+&quot;))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$"
                    class="brz-input brz-forms2__field email-input">
                <button class="btn btn-primary join-btn" (click)="SaveEmail()">Join our list</button>
                <div class="overlay-email" *ngIf="isOverlayEmail">Successful sent message</div>
            </li>
        </ul>   
  </div>
</nav>
<!-- End Navbar -->

<!-- Start Main -->
<div #home style="margin:0 0 10px 0">
    <ng-contauiner>
        <div class="main-image" [style.background-image]="'url(../../../../../assets/images/mainbg.jpg)'">
            <div class="main-overlay">
                <div class="main-container">
                    <h1>    
                        <span class="brz-span brz-icon css-1gcayuf">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         viewBox="0 0 24 24" xml:space="preserve" class="brz-icon-svg" data-type="glyph" data-name="check-bold">
                         <g class="nc-icon-wrapper" fill="currentColor">
                        <polygon fill="currentColor" points="2,11 4,9 9,12 20,4 22,6 9,19 "></polygon></g></svg></span> 
                        <span style="margin-top: 5px;"> Sponsors </span></h1>
                    <p >Access multiple CRE capital structures and hundreds of
                        undiscovered capital providers in a snap.</p>
                </div>

                <div class="main-container">
                    <h1> <span class="brz-span brz-icon css-1gcayuf">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         viewBox="0 0 24 24" xml:space="preserve" class="brz-icon-svg" data-type="glyph" data-name="check-bold">
                         <g class="nc-icon-wrapper" fill="currentColor">
                        <polygon fill="currentColor" points="2,11 4,9 9,12 20,4 22,6 9,19 "></polygon></g></svg></span> 
                        Capital providers</h1>
                    <p style="font-weight: 500;" >We use artificial intelligence to streamline your deal and origin
                        process by providing the data and metrics to underwrite deals, so you can get a yes/no quickly.
                    </p>

                    <p>CapSnap leverages AI to build multiple capital stack 
                        executions in seconds and matches the right capital partners to projects.</p>
                    <button class="btn btn-primary discover-more">Discover More</button>
                </div>
                <div class="main-container">
                    <h1 style="color: #0cacf4; font-weight: 600;font-size: 1.6rem;">The Problem</h1>
                    <p style="font-weight: 600;">Sponsors and Capital often miss each other
                        because of the infinite ways to build CRE capital stacks.</p>
                    <p style="margin-top: 30px; font-weight: 400;">Many capital stacks end up being less optimal for all involved,
                        leading to deal fallouts, a misasligned interested between parties.</p>
                    <p style="margin-top: 30px; font-weight: 400;">CapSnap leverages AI to bridge these gaps in the market:</p>
                    <div style="margin: 20px 0;">
                       <p class="list"> <span class="brz-span brz-icon css-1gcayuf">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         viewBox="0 0 24 24" xml:space="preserve" class="brz-icon-svg" data-type="glyph" data-name="check-bold">
                         <g class="nc-icon-wrapper" fill="currentColor">
                        <polygon fill="currentColor" points="2,11 4,9 9,12 20,4 22,6 9,19 "></polygon></g></svg></span>  Leverage technology to solve the challenges of considering multiple capital stack execution options</p>
                       <p class="list"> <span class="brz-span brz-icon css-1gcayuf">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         viewBox="0 0 24 24" xml:space="preserve" class="brz-icon-svg" data-type="glyph" data-name="check-bold">
                         <g class="nc-icon-wrapper" fill="currentColor">
                        <polygon fill="currentColor" points="2,11 4,9 9,12 20,4 22,6 9,19 "></polygon></g></svg></span>  Match the right people in the market under the specific circumstances of your project</p>
                       <p class="list"> <span class="brz-span brz-icon css-1gcayuf">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         viewBox="0 0 24 24" xml:space="preserve" class="brz-icon-svg" data-type="glyph" data-name="check-bold">
                         <g class="nc-icon-wrapper" fill="currentColor">
                        <polygon fill="currentColor" points="2,11 4,9 9,12 20,4 22,6 9,19 "></polygon></g></svg></span>  See designs for capital structures you may otherwise overlook</p>
                       <p class="list"> <span class="brz-span brz-icon css-1gcayuf">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         viewBox="0 0 24 24" xml:space="preserve" class="brz-icon-svg" data-type="glyph" data-name="check-bold">
                         <g class="nc-icon-wrapper" fill="currentColor">
                        <polygon fill="currentColor" points="2,11 4,9 9,12 20,4 22,6 9,19 "></polygon></g></svg></span>  Uncover capital partners and deal structures that did not previously exist.</p>
                    </div>
                    <!-- <button class="btn btn-primary">Discover More</button> -->
                </div>
            </div>
        </div>
    </ng-contauiner>
</div>
<!-- End Main  -->

<!-- Start testimonial -->
<div class="testimonial">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-md-12">
                <div class="section-header">
                    <h2>Our Testimonials</h2>
                    <div class="line"><span class="divider-center"></span></div>
                </div>
                <h1 class="testimonial-title">What Our Customers Are Talking About ?</h1>
                <p class="testimonial-content" style="margin-top: 30px;">Prior to CapSnap, I would only focus on a single capital
                    stack execution, and that process would take
                    weeks.My capital advisor often took several weeks to turn around a single structure, then several more
                    weeks to find lenders and investors who could fit that capital stack.</p>
                    <p class="testimonial-content">
                    And if that stack didn't work, a month was wasted and I was back at the starting line.
                    Not to mention other potential capital stacks were never considered.
                    </p>
                    <p class="testimonial-content">
                    Most capital advisors focus on one execution. Due to the complexity of building out a capital stack,
                    the options they bring are greatly limited, which hurts me and all other parties invovled in getting
                    the deal to move forward.
                    </p> 
                    <p class="testimonial-content">
                    With CapSnap, I get multiple structures and more ways to capitalize our deal. In addition, I am
                    presented
                    with pros and cons of each and what the numbers would look like for each solution. I am able to find
                    new capital structures that I would have never considered.
                    </p>
                    <p class="testimonial-content">
                    This makes me look like a hero to all parties, saves a tremendous amount of time and helps me do my
                    job faster, more effectively and with more certainty and less 'dead time' than ever before.
                    </p>
                <P style="font-size: 21px;font-weight: 500;">Washington DC Developer</P>
            </div>
        </div>
    </div>
</div>

<!-- End testimonial -->

<!-- Start Contact us -->
<!-- <section id="contact-us" [style.background-image]="'url(../../../../../assets/images/contact-us-bg.jpg)'">
    <div class="container">
        <div class="row wow fadeInUp">
            <div class="col-lg-7">
                <div class="section-header">
                    <h2 style="text-transform: uppercase;">Contact US</h2>
                    <div class="line"> <span class="divider-center"></span> </div>
                </div>
                <div class="form" style="margin-top: 30px;">
                    <form  class="contactForm" #formRef="ngForm" (ngSubmit)="onSubmit(formRef.value)">
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <input type="text" class="form-control" name="name"  placeholder="Your Name"
                                ngModel #NameRef="ngModel" required [class.ctrl-error] = "NameRef.touched &&
                                NameRef?.errors?.required" minlength="3" />

                            </div>

                            <div class="form-group col-lg-6">
                                <input  type="email" class="form-control" name="email" ngModel #emailRef="ngModel"  placeholder="Email Address" />
                                <div class="validation"></div>
                            </div>

                            <div class="form-group col-lg-6">
                                <input  type="text" class="form-control" name="subject"  placeholder="Subject"
                                ngModel #subject="ngModel" required [class.ctrl-error] = "subject.touched &&
                                subject?.errors?.required" minlength="3" />
                                <div class="validation"></div>
                            </div>

                            <div class="form-group col-lg-6">
                                <input type="number" class="form-control" placeholder="Phone Number"  name="phoneNumber" ngModel #phoneNumber="ngModel" />
                                <div class="validation"></div>
                            </div>
                      
                            <div class="form-group">
                                <textarea  type="text" class="form-control" name="message"  
                                ngModel #message="ngModel" required [class.ctrl-error] = "message.touched &&
                                message?.errors?.required" minlength="3" 
                                placeholder="Write Message"></textarea>
                                <div class="validation"></div>
                            </div>
                        </div>
                 
                        <div class="text-center"><button type="submit" title="Send Message"  >Send Message</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-5 ">
                <div class="contact-about">

                    <img src="../../../assets/images/contact-us.png" alt="">

                </div>
            </div>





        </div>

    </div>
</section> -->
<!-- #contact -->
  <!-- Start Footer  -->

  <div id="footer" style="background-color: #fff;">
    
    <div class="container text-center">
        <div class="row">
     
            
        <div class=" col-md-6 col-sm-12" style="display: flex; justify-content: center; align-items: center;   text-align: justify;  flex-direction: column;    ">
            <div class="section-header" style="margin-top: 0;">
                <h2 style="text-transform: uppercase;">Contact US</h2>
                <div class="line"> <span class="divider-center"></span> </div>
            </div>
            <div class="form" style="margin-top: 30px;">
                <form  class="contactForm" #formRef="ngForm" (ngSubmit)="onSubmit(formRef.value)">
                    <div class="row">
                        <div class="form-group col-lg-6">
                            <input type="text" class="form-control" name="name"  placeholder="Your Name"
                            ngModel #NameRef="ngModel" required [class.ctrl-error] = "NameRef.touched &&
                            NameRef?.errors?.required" minlength="3" />
                            <!-- <div *ngIf="NameRef.touched &&
                            NameRef?.errors?.required" class="error-message">
                            The name is required.
                            </div> -->
                        </div>

                        <div class="form-group col-lg-6">
                            <input type="email" class="form-control" name="email" ngModel #emailRef="ngModel" placeholder="Email Address"/>
                            <div class="validation"></div>
                        </div>

                        <div class="form-group col-lg-6">
                            <input  type="text" class="form-control" name="subject"  placeholder="Subject"
                            ngModel #subject="ngModel" required [class.ctrl-error] = "subject.touched &&
                            subject?.errors?.required" minlength="3" />
                            <div class="validation"></div>
                        </div>

                        <div class="form-group col-lg-6">
                            <input type="number" class="form-control" placeholder="Phone Number"  name="phoneNumber" ngModel #phoneNumber="ngModel" />
                            <div class="validation"></div>
                        </div>
                  
                        <div class="form-group">
                            <textarea  type="text" class="form-control" name="message"  
                            ngModel #message="ngModel" required [class.ctrl-error] = "message.touched &&
                            message?.errors?.required" minlength="3" 
                            placeholder="Write Message"></textarea>
                            <div class="validation"></div>
                        </div>
                    </div>
             
                    <div *ngIf="isOverlay" class="k-overlay">Successful sent message</div>
                    <div class="text-center" style="margin-top: 20px;">
                        <button type="submit" title="Send Message" class="send-msg" (click)="sendMessage()">Send Message</button>
                    </div>

                </form>
            </div>
        </div>
        <div class="offset-md-1 col-md-5 col-sm-12">
            <img src="../../../assets/images/logo.png" alt="" >
            <p style="font-size: 24px;line-height: 39px; text-align: justify; color: #424242; margin-top: 22px;">
               CapSnap was built by compounding decades of sponsor experience, capital market expertise and
               cutting-edge technology with the purpose of bridging the gap between the technology of today
               and the antiquated, tedious, and often manual processes of structured finance in CRE.
           </p>
           </div>
      </div>
       
    </div>
  </div>
  
  <!-- End Footer  -->   
