export class GeneralDeals{
    deals:Deals[];
}

export class Deals{
    noi: number;
    address: string;
    sponsorName: string;
    shortDescription: string;
    status?: any;
    isGroundLease: boolean;
    ltv?: number;
    ltc?: number;
    loanAmount?: number;
    loanTerm: string;
    loanType: string;
    state: string;
    city: string;
    propertyTypeId?: number;
    title: string;
    pipedriveId: number;
    type:string;
}
