import { Component, OnInit, isDevMode, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { General, Quotes, Sheet } from 'src/app/modals/domain';
import { ClientService } from 'src/app/services/client.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ViewportScroller } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralDeals } from 'src/app/modals/deals';

@Component({
  selector: 'app-kayak-home',
  templateUrl: './kayak-home.component.html',
  styleUrls: ['./kayak-home.component.css']
})
export class KayakHomeComponent implements OnInit {
  General:GeneralDeals;
  constructor(public activatedRoute: ActivatedRoute, public router: Router, private ClientService: ClientService
   ,private spinner: NgxSpinnerService, private viewportScroller: ViewportScroller, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.General = new GeneralDeals();
    this.GetDeals();
  }

  GetDeals() {
    this.ClientService.GetDeals().subscribe(res => {
      this.General.deals =  res ;
    })
  }

}
