import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FileComponent } from './components/file/file.component';
import { HomeComponent } from './components/home/home.component';
import { KayakHomeComponent } from './components/kayak-home/kayak-home.component';
import { MainComponent } from './components/main/main.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { QuotesComponent } from './components/quotes/quotes.component';

const routes: Routes = [
  // {path:'kayak',component:KayakHomeComponent},
  {path:'',component:MainComponent},
  {path:'page' , component:HomeComponent} ,
  {path:'main' , component:MainComponent} ,
  {path:'quotes' , component:QuotesComponent} ,

  // {path:'files/:macroid/:filename' , component:FileComponent} ,

];

@NgModule({
  imports: [RouterModule.forRoot(routes , { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
