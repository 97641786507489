<ngx-spinner bdColor="rgba(0, 0, 0, 0.9)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>


<nav class="navbar navbar-expand-lg navbar-light bg-light" style="    padding: 20px;">
  <div style="display: flex;">
    <img src="../../../assets/images/logo.png" alt="Black and White Star in Circle">
    <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" style="    height: 44px;"
      data-target="#navbarColor03" aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation"
      (click)="isShown = !isShown">
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>

  <div class="collapse navbar-collapse" id="navbarColor03" [ngbCollapse]="isShown">
    <ul class="navbar-nav mr-auto nav-ul">
      <li class="nav-item active" style="cursor: pointer; font-weight: 500;">
        <a class="nav-link" style="font-weight: 600;font-size: 18px; padding: 10px 15px; "
          (click)="scroll(home)">Home</a>
      </li>
      <li class="nav-item" style="cursor: pointer; ">
        <a class="nav-link" style="font-weight: 600;font-size: 18px; padding: 10px 15px; "
          (click)="scroll(parameters)">Deal Data</a>
      </li>
      <li class="nav-item" style="cursor: pointer;" *ngIf="Isfiles()">
        <a class="nav-link" style="font-weight: 600;font-size: 18px;  padding: 10px 15px;"
          (click)="scroll(files)">Files</a>
      </li>
      <!-- <li class="nav-item" style="cursor: pointer;">
        <button class="contact-us" (click)="scroll(Contact)">Fill Quote</button>
      </li> -->
      <li class="nav-item" style="cursor: pointer;">
        <button class="contact-us" (click)="scroll(quote)">Fill Quote</button>
        <!-- [routerLink]="['/quotes']" -->
      </li>
    </ul>

  </div>
</nav>

<div #home style="margin:0 0 10px 0">

  <!-- <div class="showcase" [style.background-image]="'url(http://40.76.123.27:8096/api/deal/ImageByGroup?key='+General.Key+'&groupid='+General.GroupId+'&image='+image+')'" >
    <img src="http://40.76.123.27:8096/api/deal/ImageByGroup?key={{General.Key}}&groupid={{General.GroupId}}&image={{image}}"
    style="width: 100%;" alt="Random first slide">
    <div class="overlay">
      <h1 *ngIf="General.macroDeal?.title">{{General.macroDeal?.title}}</h1>
    </div>
  </div> -->

  <ng-contauiner *ngFor="let image of General.MainImage">
    <div class="big-image"
      [style.background-image]="'url(https://lender.capsnap.ai/api/api/deal/ImageByGroup?key='+General.Key+'&groupid='+General.GroupId+'&image='+image+')'">
      <div class="overlay">
        <h1 *ngIf="General.macroDeal?.title" style="  font-family: 'Raleway', sans-serif ; font-weight: 600;">
          {{General.macroDeal?.title}}</h1>
        <!-- <button class="btn" style="  background-color: #2ecc71;
          color: #fff;  border: none;font-size: 20px;border-radius: 20px;padding: 5px 27px;font-weight: 400;
          letter-spacing: 2px;">Description</button> -->
        <!-- <p>A full width background image built with HTML & CSS.</p> -->
      </div>
    </div>
  </ng-contauiner>


</div>

<!-- start ABOUT -->
<div id="about" #Description>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-12">
        <div class="section-header">
          <h2>Property Description</h2>
          <div class="line"> <span class="divider-center"></span> </div>
        </div>
        <div class="about-text">
          <!-- <p *ngIf="General.macroDeal?.description"
            style="line-height: 1.67em;font-size: 20px; letter-spacing: 1px; margin-top: 20px;white-space: pre-line">
            {{General.macroDeal?.description}}</p> -->
            <p *ngIf="PropDesc" [innerHTML]="PropDesc" ></p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end ABOUT -->


<!-- Start Owl Images -->
<div id="services" #Images style="margin: 30px 0 0;">
  <div class="container" *ngIf="General.images?.length > 0">
    <div class="section-header">
      <h2>Property Photos</h2>
      <div class="line"> <span class="divider-center"></span> </div>
    </div>
    <div class="row" style="margin-top: 20px;">
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let image of General.images">
          <img style="display: block; max-height: 300px;max-width: 300px; padding: 10px; cursor: pointer;"
            (click)="open(LargeImg , General.GroupId,image)" decoding="async"
            src="https://lender.capsnap.ai/api/api/deal/ImageByGroup?groupId={{General.GroupId}}&image={{image}}&key={{General.Key}}"
            alt="{{image}}">
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>
<!-- End Owl Images -->

<!-- Start Images Popup -->
<ng-template #LargeImg let-modal>

  <div class="modal-header" style="display: flex;justify-content: end; padding: 15px 0;">
    <button type="button" aria-label="Close" (click)="modal.dismiss('Cross click')"
      style="background-color: transparent;color: #fff;border: none;font-size: 50px; outline: none; padding: 0; ">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <ngb-carousel>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img class="first-img"
          src="https://lender.capsnap.ai/api/api/deal/ImageByGroup?groupId={{grpId}}&image={{img}}&key={{General.Key}}"
          decoding="async" alt="{{img}}">
      </div>
    </ng-template>
    <ng-template ngbSlide *ngFor="let image of General.images">
      <div class="picsum-img-wrapper">
        <img class="first-img"
          src="https://lender.capsnap.ai/api/api/deal/ImageByGroup?groupId={{General.GroupId}}&image={{image}}&key={{General.Key}}"
          decoding="async" alt="{{image}}">
      </div>
    </ng-template>
  </ngb-carousel>

</ng-template>
<!-- End Images Popup -->

<!-- start Deal Data -->



<div id="DataSource" #parameters style="margin: 50px 0 0;" >
  <ng-container *ngIf="General.GroupDataSource?.length > 1">
  <div class="container" >
    <div class="row">
      <div class="col-xs-12 col-md-12">
        <div class="about-text">
          <div class="section-header">
            <h2>Property Data</h2>
            <div class="line"> <span class="divider-center"></span> </div>
          </div>
          <ng-container *ngIf="General.GroupDataSource?.length > 1">
            <div style="margin: 30px auto; text-align: center;">
              <div *ngFor="let DataSource of General.GroupDataSource" class="dataSource-tabs">
                <button (click)="scrollToDataSource(DataSource.id)" class="datasourceBtn">{{DataSource.title}}</button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngFor="let DataSource of General.GroupDataSource">

            <div data-testid="svgRoot-comp-jxg2gae811" class="_3bLYT _2OIRR dealData-header" id="{{DataSource.id}}">

              <span class="brz-span brz-icon css-1gcayuf">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                  y="0px" viewBox="0 0 24 24" xml:space="preserve" class="brz-icon-svg" data-type="glyph"
                  data-name="check-bold">
                  <g class="nc-icon-wrapper" fill="currentColor">
                    <polygon fill="currentColor" points="2,11 4,9 9,12 20,4 22,6 9,19 "></polygon>
                  </g>
                </svg></span>
              <span class="datasource-title">{{DataSource.title}}</span>
            </div>
            <p class="DataSource-intro">{{DataSource.intro}}</p>

            <ng-container *ngIf="DataSource.formula=='chart'">
              <div class="container">
                <div style="display: flex; justify-content: center;">
                  <img class="chart-img"
                    src="http://groundlease.tripgenius.com/{{DealId}}/charts/{{DataSource.namedRange}}" />
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="DataSource.formula!='chart'">


              <table class="table table-bordered mt-3"
                style="border:1px solid rgb(95 95 95 / 87%) ; font-family: sans-serif;">
                <tbody>
                  <tr>
                    <td *ngFor="let item of DataSource.head"> <span style="font-weight: bold; "> {{item}} </span></td>
                  </tr>
                  <!-- 'font-family': cell.style?.family, -->

                  <tr *ngFor="let x of GetRows(DataSource.Sheet); let i = index">
                    <td *ngFor="let cell of GetRowCells(DataSource.Sheet, x)" [ngStyle]="{
                  'font-weight': cell.style?.bold == true ? 'bold' : 'normal' ,
                  'font-style': cell.style?.italic == true ? 'italic' : 'normal' ,
                  'text-decoration': cell.style?.underlined == true ? 'underline' : 'none' ,
                  'background-color': cell.style?.background?.rgb ,
                  'color': cell.style?.fontColor?.rgb ,
                  'font-size': cell.style?.size  == '0' ? '17px': cell.style?.size }">
                      <!-- 'font-weight' : cell.style?.bold == true ? 'bold' : 'none' ,  -->
                      <span *ngIf="cell.value=='icon'">
                        <svg preserveAspectRatio="xMidYMid meet" data-bbox="40.026 40.026 319.948 319.948"
                          viewBox="40.026 40.026 319.948 319.948" height="45" width="45"
                          xmlns="http://www.w3.org/2000/svg" data-type="color" style="margin-right:10px ;"
                          role="presentation" aria-hidden="true">
                          <defs>
                            <style>
                              #comp-jxg2gae811 svg [data-color="1"] {
                                fill: #000000;
                              }
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M200 40.026c-88.351 0-159.974 71.623-159.974 159.974S111.649 359.974 200 359.974c88.352 0 159.974-71.623 159.974-159.974S288.352 40.026 200 40.026zm17.786 228.68h-50.202l-.638-1.422.054-.117-12.914-28.099v-.001l-35.254-76.47h51.493l22.422 48.548 36.928-79.852h51.494l-63.383 137.413z"
                              fill="#000001" data-color="1"></path>
                            <path fill="#000001" opacity=".3"
                              d="M192.747 211.146l-25.039 57.56-10.601-23.065 17.82-73.079 17.82 38.584z"
                              data-color="1"></path>
                          </g>
                        </svg>
                      </span>
                      <span *ngIf="cell.value=='DealTitle'"> {{General.macroDeal?.title}}</span>
                      <span *ngIf="cell.value!=='icon' && cell.value!=='DealTitle'"> {{cell.value}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
          </ng-container>

        </div>
      </div>
    </div>
  </div>
</ng-container>
</div>
<!-- end Deal Data -->
<!-- start files -->
<div id="files" #files>
  <div class="container" >
    <div class="row">
      <div class="col-xs-12 col-md-12">
        <div class="about-text">
          <div class="section-header">
            <h2 style="font-weight: bolder;">Files</h2>
            <div class="line"> <span class="divider-center"></span> </div>
          </div>
          <div *ngFor="let category of General.categories">
            <h1 *ngIf="GetBooleanCategory(category)">
              <svg preserveAspectRatio="xMidYMid meet" data-bbox="40.026 40.026 319.948 319.948"
                viewBox="40.026 40.026 319.948 319.948" height="30" width="30" xmlns="http://www.w3.org/2000/svg"
                data-type="color" style="margin-right:10px ; margin-bottom: 2px;" role="presentation"
                aria-hidden="true">
                <defs>
                  <style>
                    #comp-jxg2gae811 svg [data-color="1"] {
                      fill: #000000;
                    }
                  </style>
                </defs>
                <g>
                  <path
                    d="M200 40.026c-88.351 0-159.974 71.623-159.974 159.974S111.649 359.974 200 359.974c88.352 0 159.974-71.623 159.974-159.974S288.352 40.026 200 40.026zm17.786 228.68h-50.202l-.638-1.422.054-.117-12.914-28.099v-.001l-35.254-76.47h51.493l22.422 48.548 36.928-79.852h51.494l-63.383 137.413z"
                    fill="#000001" data-color="1"></path>
                  <path fill="#000001" opacity=".3"
                    d="M192.747 211.146l-25.039 57.56-10.601-23.065 17.82-73.079 17.82 38.584z" data-color="1"></path>
                </g>
              </svg> {{category.name}}
            </h1>
            <div *ngFor="let file of GetCategoryFiles(category)">
              <p class="file-name"> <img src="../../../assets/images/pdf_4726010.png" alt="" style="height: 35px; margin-right: 10px;"> {{file.name}}

                <a download href="https://lender.capsnap.ai/api/api/landing/Download?fileId={{file.id}}"
                  title="Download" (click)="LogAction(General.Key , 'Downlaod File' , file.id)"
                  style="color: #000000; margin-left: 10px;"> <i class="fas fa-download"></i></a>

                <a href="https://lender.capsnap.ai/api/api/landing/Preview?fileId={{file.id}}"
                  (click)="LogAction(General.Key , 'Preview File' , file.id)" target="_blank" title="View"
                  style="color: #000000; margin-left: 10px;"><i class="fas fa-eye" style="cursor: pointer;"></i> </a>

              </p>
            </div>
          </div>

          <!-- <div *ngFor="let item of AllFiles">
            <div style="padding:20px">
              <a style="color: #000; font-size: 18px;" href="https://files.capsnap.ai/{{DealId}}/{{item}}" target="_blank">{{item}}</a> 
              </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end files -->

<!-- Contact Section -->
<!-- <div class="container">
    <div id="contact" #Contact>
       <div class="row">
          <div class="col-md-6  contact-info" >
            <div class="contact-item" style="text-align: center; padding-top: 60px;">
              <h4>Contact Info</h4>
              <div class="contact-item">
                <p style="font-size: 18px;">Joe Bous - Valor GLV</p>
              </div>
              <p><span style="color: #000000;"><b>Address:</b></span>4619 41st St, Washington DC 20016<br>
                Washington, DC 20016</p>
                <p><span style="color: #000000;"><b>Phone:</b></span>(703) 338-8555</p>
                <p><span style="color: #000000;"><b>Email:</b></span>joe@groundlease.co</p>
                <p><span style="color: #000000;"><b>Website:</b></span>www.groundlease.co</p>
            </div>
          </div>
          <div class="col-md-6">
            <img class="lowerimg"
             src="https://static.wixstatic.com/media/12316e44d26649c69a935420b4dbb784.jpg/v1/fill/w_632,h_772,fp_0.50_0.50,q_85,usm_0.66_1.00_0.01/12316e44d26649c69a935420b4dbb784.webp"
             alt="Conference Room" >
          </div>
    </div>
  </div>
</div> -->

<!-- Start Contact us -->
<!-- <section id="contact-us"  [style.background-image]="'url(../../../../../assets/images/contact-us-bg.jpg)'">
  <div class="container" #Contact>
      <div class="row wow fadeInUp">
          <div class="col-lg-7">
              <div class="section-header">
                  <h2 style="font-weight: bolder;">Contact US</h2>
                  <div class="line"> <span class="divider-center"></span> </div>
              </div>
              <div class="form" style="margin-top: 30px;">
                  <form action="" method="post" role="form" class="contactForm">
                      <div class="row">
                          <div class="form-group col-lg-6">
                              <input type="text" name="name" class="form-control" id="name" placeholder="Your Name"
                                  data-rule="minlen:4" />
                              <div class="validation"></div>
                          </div>
                          <div class="form-group col-lg-6">
                              <input type="email" class="form-control" name="email" id="email"
                                  placeholder="Email Address" data-rule="email" />
                              <div class="validation"></div>
                          </div>
                          <div class="form-group col-lg-6">
                              <input type="text" class="form-control" name="Phone" id="Phone" placeholder="Subject"
                                  data-rule="minlen:4" />
                              <div class="validation"></div>
                          </div>
                          <div class="form-group col-lg-6">
                              <input type="text" class="form-control" name="Phone" id="Phone" placeholder="Phone Number"
                                  data-rule="minlen:4" />
                              <div class="validation"></div>
                          </div>

                          <div class="form-group">
                              <textarea class="form-control" name="message" rows="5" data-rule="required"
                                  placeholder="Write Message"></textarea>
                              <div class="validation"></div>
                          </div>
                      </div>




                      <div class="text-center"><button type="submit" title="Send Message" >Send Message</button>
                      </div>
                  </form>
              </div>
          </div>
          <div class="col-lg-5 ">
              <div class="contact-about">

                  <img src="../../../assets/images/contact-us.png" alt="">

              </div>
          </div>

      </div>

  </div>
</section> -->

<!-- Start Quote Section -->
<div id="footer" style="background-color: #f7f7f7; padding: 30px 0;" #quote>
  <div class="container">
    <div class="row">
      <div class="section-header">
        <h2 style="font-weight: bolder;">Fill Quote</h2>
        <div class="line"> <span class="divider-center"></span> </div>
      </div>
      <div style="display: flex; justify-content:center">
        <p class="quote-intro"> <span>
            <!-- <svg preserveAspectRatio="xMidYMid meet" data-bbox="40.026 40.026 319.948 319.948"
            viewBox="40.026 40.026 319.948 319.948" height="45" width="45" xmlns="http://www.w3.org/2000/svg"
            data-type="color" style="margin-right:10px ;" role="presentation" aria-hidden="true">
            <defs>
              <style>
                #comp-jxg2gae811 svg [data-color="1"] {
                  fill: #000000;
                }
              </style>
            </defs>
            <g>
              <path
                d="M200 40.026c-88.351 0-159.974 71.623-159.974 159.974S111.649 359.974 200 359.974c88.352 0 159.974-71.623 159.974-159.974S288.352 40.026 200 40.026zm17.786 228.68h-50.202l-.638-1.422.054-.117-12.914-28.099v-.001l-35.254-76.47h51.493l22.422 48.548 36.928-79.852h51.494l-63.383 137.413z"
                fill="#000001" data-color="1"></path>
              <path fill="#000001" opacity=".3"
                d="M192.747 211.146l-25.039 57.56-10.601-23.065 17.82-73.079 17.82 38.584z" data-color="1"></path>
            </g>
          </svg> -->
          </span>If you are interested in {{General.macroDeal?.title}}
           <br><span style="font-weight:700">  Please submit your quote </span></p>
        <!-- <button class="btn btn success" [routerLink]="['/quotes']"
        style="background: #05aaf3;color: #fff;font-size: 21px;">Fill Quote</button> -->
      </div>
      <form (ngSubmit)="f.form.valid && onSubmitAddQuote()" #f="ngForm">
        <div class="row">
          <div class="col-md-3 offset-md-3">
            <div class="form-group form-group-default">
              <label class="quote-label">LTV</label>
              <input type="text" class="form-control quotes-inp" name="ltv"
              [(ngModel)]="newQuote.ltv" #ltv="ngModel"
              required>
              <!-- [ngClass]="{ 'is-invalid': f.submitted && ltv.invalid  }" -->
            </div>
          </div>

          <div class="col-sm-3 ">
            <div class="form-group form-group-default">
              <label class="quote-label">Amount</label>
              <input type="text" class="form-control quotes-inp" name="amount" [(ngModel)]="newQuote.amount"
                #amount="ngModel"  required>
                <!-- [ngClass]="{ 'is-invalid': f.submitted && amount.invalid }" -->
            </div>
          </div>

          <div class="col-md-3 offset-md-3" >
            <div class="form-group form-group-default">
              <label class="quote-label">Fees</label>
              <input type="text" class="form-control quotes-inp" name="fees" [(ngModel)]="newQuote.fees"
                #address="ngModel" [ngClass]="{ 'is-invalid': f.submitted && address.invalid }">
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group form-group-default">
              <label class="quote-label">Up front Reserves</label>
              <input type="text" class="form-control quotes-inp" name="UpfrontReserves"
                [(ngModel)]="newQuote.upfrontReserves" #UpfrontReserves="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && UpfrontReserves.invalid }">
            </div>
          </div>

          <div class="col-md-3  offset-md-3">
            <div class="form-group form-group-default">
              <label class="quote-label">Min DSCR</label>
              <input type="text" class="form-control quotes-inp" name="MinDSCR" [(ngModel)]="newQuote.minDSCR"
                #MinDSCR="ngModel" [ngClass]="{ 'is-invalid': f.submitted && MinDSCR.invalid }">
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group form-group-default">
              <label class="quote-label">Rescource</label>
              <input type="text" class="form-control quotes-inp" name="Rescource" [(ngModel)]="newQuote.rescource"
                #Rescource="ngModel" [ngClass]="{ 'is-invalid': f.submitted && Rescource.invalid }">
            </div>
          </div>

          <div class="col-md-3 offset-md-3">
            <div class="form-group form-group-default">
              <label class="quote-label">Rate</label>
              <input type="text" class="form-control quotes-inp" name="Rate" [(ngModel)]="newQuote.rate" #Rate="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && Rate.invalid }">
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group form-group-default">
              <label class="quote-label">Loan Term</label>
              <input type="text" class="form-control quotes-inp" name="LoanTerm" [(ngModel)]="newQuote.loanTerm"
                #LoanTerm="ngModel" [ngClass]="{ 'is-invalid': f.submitted && Rate.invalid }">
            </div>
          </div>

          <div class="col-md-6  offset-md-3">
            <div class="form-group form-group-default">
              <label class="quote-label">Armortization</label>
              <input type="text" class="form-control quotes-inp" name="Armortization"
                [(ngModel)]="newQuote.armortization" #Armortization="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && Armortization.invalid }">
            </div>
          </div>


          <div class="col-md-6 offset-md-3">
            <div class="form-group form-group-default" style="display: flex;flex-direction: column;">
              <label>Notes</label>
              <textarea class="form-control" name="note" id="" cols="5" rows="5" name="notes"
                [(ngModel)]="newQuote.quoteNotes" #notes="ngModel"
                style="border-color: #c8c8ca; padding: 10px; color:#495057; background-color: #fff;">
              </textarea>
            </div>
          </div>
        </div>

        <div style="margin-top: 28px;display: flex;justify-content: center;">
          <button type="submit" class="btn main-close  ml-2" style="background-color: #3498db; color:#fff ; font-weight: 500;"
            data-dismiss="modal" [disabled]="f.invalid" (click)="submitQuote()">Submit Quote</button>
        </div>
        <div class="success-msgg" *ngIf="isOverlaySubmit">
          <i class="fa fa-check"></i>
          Success.
        </div>
      </form>
    </div>
  </div>
</div>
<!-- End Quote Section  -->

<!-- Start Footer -->
<div id="footer" style="background-color: #fff;" #Contact>
  <div class="container text-center">
    <div class="row">
      <div class=" col-md-6 col-sm-12"
        style="display: flex; justify-content: center; align-items: center;   text-align: justify;  flex-direction: column;    ">
        <div class="section-header" style="margin-top: 0;">
          <h2 style="text-transform: uppercase;">Contact US</h2>
          <div class="line"> <span class="divider-center"></span> </div>
        </div>
        <div class="form" style="margin-top: 30px;">
          <form class="contactForm" #formRef="ngForm" (ngSubmit)="onSubmit(formRef.value)">
            <div class="row">
              <div class="form-group col-lg-6">
                <input type="text" style="background-color: #fff;"
                class="form-control" name="name" placeholder="Your Name"  #NameRef="ngModel"
                   [class.ctrl-error]="NameRef.touched &&
                            NameRef?.errors?.required" minlength="3"  ngModel required/>
                <!-- <div *ngIf="NameRef.touched &&
                            NameRef?.errors?.required" class="error-message">
                            The name is required.
                            </div> -->
              </div>

              <div class="form-group col-lg-6">
                <input type="email" class="form-control" name="email"  #emailRef="ngModel"
                  placeholder="Email Address" ngModel required
                  style="background-color: #fff;"/>
                <div class="validation"
                ></div>
              </div>


              <div class="form-group">
                <textarea type="text" class="form-control" name="message"  #message="ngModel"
                  [class.ctrl-error]="message.touched &&
                            message?.errors?.required" minlength="3" placeholder="Write Message" ngModel required></textarea>
                <div class="validation"></div>
              </div>
            </div>

            <!-- <div  class="k-overlay">Successful sent message</div> -->
            <div class="success-msg" *ngIf="isOverlay">
              <i class="fa fa-check"></i>
              This is a success message.
            </div>
            <div class="text-center" style="margin-top: 20px;">
              <button type="submit" title="Send Message" class="send-msg"
              [disabled]="formRef.invalid"
             (click)="sendMessage()">Send Message</button>
            </div>

          </form>
        </div>
      </div>
      <div class="offset-md-1 col-md-5 col-sm-12">
        <img src="../../../assets/images/logo.png" alt="">
        <p style="font-size: 24px;line-height: 39px; text-align: justify; color: #424242; margin-top: 22px;">
          CapSnap was built by compounding decades of sponsor experience, capital market expertise and
          cutting-edge technology with the purpose of bridging the gap between the technology of today
          and the antiquated, tedious, and often manual processes of structured finance in CRE.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- End Footer  -->
