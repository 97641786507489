<!-- <nav class="navbar navbar-expand-lg" style="    padding: 20px;">
  <div style="display: flex;">
    <img src="../../../assets/images/logo.png" alt="Black and White Star in Circle">
    <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" style="    height: 44px;"
     data-target="#navbarColor03" aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation" >
     <span class="navbar-toggler-icon"></span>
    </button>
  </div>
</nav> -->
 <div style="background-color: #f7f7f7;    padding: 20px;">

  <div style="display: flex; justify-content: center;">
    <img src="../../../assets/images/logo.png" alt="Black and White Star in Circle" style="margin-bottom: 25px;">

  </div>
<div style="display: flex; justify-content:center">
<form>

  <h1>Fill Your Quote</h1>
  <fieldset>
    <legend><span class="number">1</span> Your basic info</legend>

    <label for="name">Name:</label>
    <input type="text" id="name" name="user_name">

    <label for="mail">Email:</label>
    <input type="email" id="mail" name="user_email">

    <label for="password">Password:</label>
    <input type="password" id="password" name="user_password">

    <label>Age:</label>
    <input type="radio" id="under_13" value="under_13" name="user_age"><label for="under_13" class="light">Under 13</label><br>
    <input type="radio" id="over_13" value="over_13" name="user_age"><label for="over_13" class="light">13 or Older</label>

  </fieldset>

  <fieldset>

    <legend><span class="number">2</span> Your profile</legend>

    <label for="bio">Biography:</label>
    <textarea id="bio" name="user_bio"></textarea>

    <label for="job">Job role:</label>
    <select id="job" name="user_job">
      <optgroup label="Web">
        <option value="frontend_developer">Front-End Developer</option>
        <option value="php_developer">PHP Developer</option>
        <option value="python_developer">Python Developer</option>
        <option value="rails_developer">Rails Developer</option>
        <option value="web_designer">Web Designer</option>
        <option value="wordpress_developer">WordPress Developer</option>
      </optgroup>
      <optgroup label="Mobile">
        <option value="android_developer">Android Developer</option>
        <option value="ios_developer">iOS Developer</option>
        <option value="mobile_designer">Mobile Designer</option>
      </optgroup>
      <optgroup label="Business">
        <option value="business_owner">Business Owner</option>
        <option value="freelancer">Freelancer</option>
      </optgroup>
    </select>

    <label>Interests:</label>
    <input type="checkbox" id="development" value="interest_development" name="user_interest"><label class="light" for="development">Development</label><br>
    <input type="checkbox" id="design" value="interest_design" name="user_interest"><label class="light" for="design">Design</label><br>
    <input type="checkbox" id="business" value="interest_business" name="user_interest"><label class="light" for="business">Business</label>

  </fieldset>

  <button type="submit">Submit</button>

</form>
</div>
</div>
