
<nav class="navbar navbar-expand-lg navbar-light " style="    padding: 20px;">
  <div style="display: flex;">
    <img src="../../../assets/images/logo/2.png" alt="Black and White Star in Circle" style="height: 100px;">
    <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" style="    height: 44px;"
      data-target="#navbarColor03" aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation"
      >
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>

</nav>
<div style="background-color: #f0f3f5; padding: 10px;">


<div class="container mt-5 mb-3">
    <div class="row justify-content-center">
      <div class="col-md-3">

      </div>

      <div class="col-md-8" >
        <ng-container *ngFor="let deal of General.deals">
          <div>
            <div class="card mb-2">
              <div class="row " style="background-color: #fff;box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px; border-radius: 10px; height: 256px;">
                <div class="col-md-3 p-0 left-img ">
                  <img src="http://40.76.123.27:8096/api/deal/Image?dealId={{deal.pipedriveId}}&image={{'main.jpg'}}" />
                </div>
                <!-- <div class="col-md-3 p-0">
                  <img src="../../../assets/images/demo/1.png" alt="" style="height: 218px; max-width:100%">
                </div> -->
                <div class="col-md-6 p-3 " style="border-right:1px solid #e5ebf0">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex flex-row align-items-center">
                      <!-- <div class="icon"> <i class="bx bxl-mailchimp"></i> </div> -->
                      <div class="c-details">
                        <h6 class="mb-0 deal-title">{{deal.title}}</h6>
                        <span style="font-size: 15px; font-weight: 400; color: #5a6872;" *ngIf="deal.status!=null||deal.state!=null">
                          <i class="fas fa-map-marker-alt" style="font-size: 14px;margin-right: 4px;"></i>{{deal.city}}, {{deal.state}}, USA</span>
                      </div>
                    </div>
                    <!-- <div class="badge"> <span>JLL Capital Markets</span> </div> -->
                  </div>
                  <div class="mt-3">
                    <div *ngIf="deal.status!=null" style="display: flex; align-items: center;" >
                      <h3 class="heading property-type">{{deal.type}}</h3>
                      <div class="bfLabel bf-cheapest">
                        <span *ngIf="deal.status == '1'">Operational</span>
                        <span *ngIf="deal.status == '2'">Rehab</span>
                        <span *ngIf="deal.status == '3'">Ground up</span>
                      </div>
                    </div>

                    <p class="ltv" style="font-weight: 400;font-size: 14px;line-height: 15px; margin-top: 5px; color: #5a6872;">
                      {{deal.shortDescription}}
                    </p>

                    <div class="mt-2 ltv">
                      <div style="display: flex;flex-direction: column;">
                        <span class="text1" *ngIf="deal.ltv!=null">LTV : {{deal.ltv}}% </span>
                        <span class="text1" *ngIf="deal.ltc!=null">LTC : {{deal.ltc}}%</span>
                        <span class="text1" *ngIf="deal.noi!=null">NOI : ${{deal.noi | number}} </span>
                      </div>
                    </div>

                    <div *ngIf="deal.sponsorName!=null" class="mt-2" style="    border-top: 1px solid #e5ebf0;
                     padding: 8px 0 0 0; display: flex; align-items: center;">
                      <span style="font-weight:500; font-size: 14px; color:#5a6872">Sponsored by {{deal.sponsorName}}</span>

                    </div>
                    <!-- <div class="mt-1">
                      <span class="text1">Andrew Girgis</span>
                    </div> -->
                  </div>
                </div>
                <div class="col-md-3 p-3 " style="display: flex;justify-content: space-between;flex-direction: column;">
                  <div>
                    <div class="d-flex" *ngIf="deal.loanAmount!=null">
                      <h1 class="doller" style="font-size: 20px; font-weight: 700; margin: 0; color: #212a30;">${{deal.loanAmount | number}}
                      </h1>
                    </div>
                    <div class="mt-1">
                      <h3 class="heading" style="margin: 0;">{{deal.loanType}}<br>
                        <span style="font-weight: bold;">{{deal.loanTerm}}</span>
                      </h3>
                    </div>
                  </div>

                  <div class="d-flex">
                    <button class="details">Details</button>
                  </div>
                </div>

              </div>

            </div>
          </div>

      </ng-container>
     </div>
  </div>
</div>
</div>
