import { Component, OnInit, isDevMode, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { General, Quotes, Sheet } from 'src/app/modals/domain';
import { ClientService } from 'src/app/services/client.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ViewportScroller } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  customOptions: any = {
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    autoplay: true,
    autoplaySpeed: 700,
    autoplayHoverPause: true,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true,

  }

  General!: General;
  arr: any[] = []
  selectedIndex!: boolean;
  DealId!: number;
  microDealId: number;
  AllFiles:string[] = []; 
  pageTitle: string = 'Default Title';

  constructor(public activatedRoute: ActivatedRoute, public router: Router, private ClientService: ClientService
    , private spinner: NgxSpinnerService, private viewportScroller: ViewportScroller, private modalService: NgbModal,private titleService: Title) {
     }


  ngOnInit(): void {
    // this.spinner.show()
    let cp1 = [1, 2, 3, 'a', 'a', 'f', 3, 4, 2, 'd', 'd']
    let cp2 = cp1.reduce((noDupes: any, x) => {
      if (noDupes.indexOf(x) === -1) {
        noDupes.push(x)
      }
      return noDupes;
    }, [])


    this.General = new General();
    this.activatedRoute.queryParams.subscribe(params => {
      this.General.Key = params['key'];
      console.log(this.General.Key);

      this.General.GroupId = params['groupId'];

      if (params['key']) {
        this.General.Key = params['key'];
        this.General.GroupId = 0;
      } else {
        this.General.GroupId = params['groupId'];
        this.General.Key = "";
      }

      this.GetImages(this.General.Key, this.General.GroupId);
      this.GetDealByGroupId(this.General.Key, this.General.GroupId);
      // this.GetDataSources(this.General.Key , this.General.GroupId );
      this.GetFileCategory();
      this.LogAction(this.General.Key, 'Landing Page Load', 0);
      // this.GetFiles(this.General.Key , this.General.GroupId);
    });
  }

  updateTitle(newTitle: string) {
    this.pageTitle = newTitle;
    this.titleService.setTitle(newTitle);
  }

  grpId: number;
  img: any;
  open(content, groupid?: number, image?: any) {
    // this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
    this.modalService.open(content, { centered: true });
    this.grpId = groupid;
    this.img = image;
  }

  LogAction(Key: string, ActionName?: string, ObjectId?: number) {
    this.ClientService.LogAction(Key, ActionName, ObjectId).subscribe(res => {
      console.log(res);
    })
  }



  // downloadFile( fileId:number ){
  //   let dealId = 0;
  //   this.ClientService.DownloadFile(this.General.Key  , fileId ,this.General.GroupId  , dealId ).subscribe(res=>{
  //     console.log(res);
  //   })
  // }

  Isfiles() {
    if (this.General.Files?.length > 0) {
      return true;
    } else {
      return false;
    };
  }


  GetCategoryFiles(category) {
    return this.General.Files.filter(r => r.categoryId == category.id);
  }

  GetBooleanCategory(category) {
    
    
    if (this.General.Files.filter(r => r.categoryId == category.id).length > 0) {
      return true;
    } else {
      false
    };

  }

  IsExcelFile(file) {
    let excelFile: boolean = false;
    var fileExt = file.name.split('.').pop();
    if (fileExt == "xlsx") {
      excelFile = true;
    }
    if (excelFile) {
      return true
    }
  }

  // return a random element from an array


  NotExcelFile(file) {
    let excelFile: boolean = false;
    var fileExt = file.name.split('.').pop();
    if (fileExt == "xlsx") {
      excelFile = false;
    }
    else {
      excelFile = true;
    }
    if (excelFile) {
      return true
    }
  }

  GetImages(Key: string, groupId: number) {
    this.ClientService.GetImagesByGroup(Key, groupId).subscribe((res: any) => {
      this.General.MainImage = res.filter((r: any) => r == 'main.jpg');
      this.General.images = res.filter((r: any) => r != 'main.jpg');
    })
  }

  isShown: boolean = true;

  GetDataSources(Key: string, groupId: number) {
    this.ClientService.GetDataSources(Key, groupId).subscribe((res: any) => {
      this.General.GroupDataSource = res;

      this.General.GroupDataSource.forEach(element => {
        if (element.headers != null) {
          element.head = element.headers.split(",")
        } else {
          element.head = [];
        }
      });

      // this.spinner.show();
      let calls = 0;
      this.General.GroupDataSource.forEach(element => {
        if (element.formula != 'chart') {
          calls++;
          this.ClientService.readformula(element.fileId, this.General.Key, this.General.GroupId, element.formula, element.namedRange).subscribe((res: any) => {
            var minX = Number.POSITIVE_INFINITY;
            var maxX = Number.NEGATIVE_INFINITY;
            minX = res.cells.sort((a: any, b: any) => a.x - b.x)[0].x;
            maxX = res.cells.sort((a: any, b: any) => b.x - a.x)[0].x;
            element.Sheet = new Sheet();
            element.Sheet.cells = res.cells;
            element.Sheet.maxX = maxX;
            element.Sheet.minX = minX;

            element.Sheet.cells.forEach(r => {
              if (r.style != null) {
                if (r.style.background != null && r.style.background.rgb != null) {
                  r.style.background.rgb = r.style.background.rgb.replace("FF", "#");
                  r.style.background = r.style.background;
                }
                r.style.fontColor.rgb = r.style.fontColor?.rgb?.replace("FF", "#");
              }

            });
            calls--;

            if (calls == 0) this.spinner.hide();
          })
        }

      });
    })
  }

  GetRows(Sheet: Sheet) {
    if (Sheet != null) {
      var RowsArray = [];
      for (let index = Sheet.minX; index <= Sheet.maxX; index++) {
        RowsArray.push(index);
      }
      return RowsArray;
    } else {
      return [];
    }
  }

  GetRowCells(Sheet: Sheet, row: any) {
    return Sheet?.cells.filter((c: any) => c.x == row).sort((a: any, b: any) => a.y - b.y);
  }

  PropDesc:string;
  GetDealByGroupId(Key: string, groupId: number) {
    this.ClientService.GetDealByGroupId(Key, groupId).subscribe((res: any) => {
      this.General.macroDeal = res.macroDeal;
      this.updateTitle(`CapSnap.AI - ${this.General.macroDeal.title}`);
      this.PropDesc =  res.dealDescription;
      this.DealId = res.macroDeal.pipedriveId;
      this.microDealId = res.microDealId;
      this.GetFiles(Key, groupId);
      this.GetDataSources(this.General.Key, this.General.GroupId);
    })
  }
 
  GetFiles(Key: string, groupId: number) {
    this.ClientService.GetGroupFiles(Key, groupId).subscribe((res: any) => {
      this.General.Files = res;
    })
  }

  GetFileCategory() {
    this.ClientService.GetFileCategories().subscribe(res => {
      this.General.categories = res;
    })
  }

  getCategory(catid: any) {
    return this.General.categories?.filter(r => r.id == catid)[0].name;
  }

  scroll(el: any) {
    var offset = 20; // sticky nav height
    var el = el;
    window.scroll({ top: (el.offsetTop - offset), left: 0, behavior: 'smooth' });
  }

  scrollToDataSource(id: any) {
    this.viewportScroller.scrollToAnchor(id);
  }

  getFileLink(DealId: number, fileName: string) {
    this.ClientService.GetFileLink(DealId, fileName).subscribe(res => {
      this.General.customFileLink = res;
    })
  }

  onSubmit(formValue) {
    console.log(formValue);
    this.ClientService.savemessage(formValue).subscribe(res => {

      console.log(res);
    })
  }

  isOverlay = false;
  isOverlaySubmit = false;
  sendMessage() {
    this.isOverlay = true;
    setTimeout(() => {
      this.isOverlay = false;
    }, 2000);
  }

  submitQuote(){
    this.isOverlaySubmit = true;
    setTimeout(() => {
      this.isOverlaySubmit = false;
    }, 2000);
  }
  isOverlayEmail = false;
  save() {
    this.isOverlayEmail = true;
    setTimeout(() => {
      this.isOverlayEmail = false;
    }, 2000);
  }

  newQuote: Quotes = new Quotes();
  @ViewChild('f') addQuoteForm;
  onSubmitAddQuote() {
    this.spinner.show();
    this.newQuote.id = this.microDealId;
    this.ClientService.AddUpdateQuote(this.newQuote).subscribe(res => {
      for (var x in this.newQuote) delete this.newQuote[x];
      this.spinner.hide();
    })

  }

  // GetLink(){
  //   if(this.General.customFileLink != null ){
  //     var link ='https://view.officeapps.live.com/op/view.aspx?src=';
  //     link+=this.General.customFileLink ;
  //     return link;
  //   }
  // }

}
