<!-- start navbar -->
<nav id="menu" class="navbar navbar-default navbar-expand-lg fixed-top">
    <div class="container">
      <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarDefault"
        aria-controls="navbarDefault" aria-expanded="false" aria-label="Toggle navigation">
        <span></span>
        <span></span>
        <span></span>
      </button>
      <img src="https://static.wixstatic.com/media/13128652dab8451db4ce3e8b1b00d5b0.png/v1/fill/w_64,h_59,al_c,q_85,usm_0.66_1.00_0.01/Black%20and%20White%20Star%20in%20Circle.webp" 
      alt="Black and White Star in Circle" class="logo-img" >
      <a class="logo"   >VALOR GROUNDLEASE VENTURES</a>
  
      <div class="navbar-collapse collapse justify-content-center" id="navbarDefault">
        <ul class="navbar-nav">
          <li class="nav-item"> 
            <a class="nav-link"  >Home</a>
          </li>
  
          <li class="nav-item">
            <a class="nav-link"   >Images</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"  >Files</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"  >Parameters</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"   >About</a>
          </li>
          <li class="nav-item">
            <button class="contact-us"  style=" font-family: Avenir;">Contact Us</button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="container" >
  <object type="application/pdf"
  [data]="fileName"
  width="852"
  height="900"
  align="right"
  style="overflow:hidden;">
</object>
</div>
 <!-- Footer Section -->
 <div id="footer">
    <div class="container text-center">
      <p>&copy; 2021 by Valor Groundlease Ventures. </p>
    </div>
  </div>