import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http:HttpClient) { }

  public GetGroupFiles( Key:string , GroupId:number):Observable<any[]>{
    return this.http.get<any[]>(`${environment.api}/deal/GetGroupFiles?key=${Key}&groupid=${GroupId}`).pipe();
  }

  public GetImagesByGroup( Key:string , GroupId:number  ):Observable<any[]>{
    return this.http.get<any[]>(`${environment.api}/deal/GetImagesByGroup?key=${Key}&groupid=${GroupId}`).pipe();
  }

  public GetDataSources( Key:string , GroupId:number  ):Observable<any[]>{
    return this.http.get<any[]>(`${environment.api}/landing/GetDataSources?key=${Key}&groupid=${GroupId}`).pipe();
  }

  public readformula(fileid:number,Key:string, GroupId:number,formula:string,namedRange:string):Observable<any[]>{
    return this.http.get<any[]>
    (`${environment.api}/landing/readformula?fileid=${fileid}&key=${Key}&groupid=${GroupId}&formula=${formula}&namedRange=${namedRange}`).pipe();
  }

  public GetDealByGroupId( Key:string , GroupId:number ):Observable<any[]>{
    return this.http.get<any[]>(`${environment.api}/deal/GetDealByGroupId?key=${Key}&groupid=${GroupId}`).pipe();
  }

  public GetFileCategories():Observable<any[]>{
    return this.http.get<any[]>(`${environment.api}/deal/GetFileCategories`).pipe();
  }

  public LogAction(Key:string ,ActionName:string ,ObjectId:number):Observable<any[]>{
    return this.http.get<any[]>(`${environment.api}/landing/LogAction?key=${Key}&actionName=${ActionName}&objectId=${ObjectId}`).pipe();
  }

  public GetFileLink(dealId:number, fileName:string):Observable<any[]>{
    return this.http.get<any[]>(`${environment.api}/deal/GetFileLink?dealId=${dealId}&fileName=${fileName}`).pipe();
  }

  public GetPDFFilePreview(dealId:number, fileName:string):Observable<any[]>{
    return this.http.get<any[]>(`${environment.api}/deal/GetPDFFilePreview?dealId=${dealId}&fileName=${fileName}`).pipe();
  }


  // public DownloadFile(Key:string , FileId:number , GroupId:number , DealId:number):Observable<any[]>{
  //   return this.http.get<any[]>(`${environment.api}/deal/DownloadFile?key=${Key}&fileId=${FileId}&groupId=${GroupId}&dealId=${DealId}`).pipe();
  // }


  public savemessage(template): Observable<any[]> {
    return this.http.post<any[]>(`${environment.api}/web/savemessage`, template).pipe();
  }

  public saveemail(email:string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api}/web/saveemail?email=${email}`).pipe();
  }

  public AddUpdateQuote(Quote: any) {
    return this.http.post<any>(`${environment.api}/deal/AddUpdateQuote`, Quote).pipe();
  }

  public GetDeals(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api}/MacroDeal/GetDeals`).pipe();
  }

  public ProjectTypes(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api}/ProjectTypes`).pipe();
  }

 

}
