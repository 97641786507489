import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.css']
})

export class FileComponent implements OnInit {
 fileName!:any;
  constructor(public activatedRoute: ActivatedRoute,public router: Router  ,private sanitizer: DomSanitizer ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      console.log(params.macroid);
      
      this.fileName = this.sanitizer.bypassSecurityTrustResourceUrl("/files/"+params.macroid+"/"+params.filename+"") ;
      console.log(this.fileName);
    })
  }

}
